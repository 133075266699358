import logo from './logo.svg';
import './App.css';
import axios from 'axios'
import {useEffect, useState} from 'react'

function App() {
  const [name, setname] = useState();
  useEffect(() => {
    const getgoogleinfo = axios.post("https://farley-renderv4-backend.onrender.com/auth/getgoogleinfo").then((res) => {
   

    console.log(res.data)
    setname(res.data)
    });
  }, []);
console.log(name)
  return (
    <div className="App">
      <header className="App-header">
  
        {/* <button onClick={async()=>{
            try {
              let carrierId = await axios.get('/users')
              console.log(carrierId)
            } catch (error) {
              console.log(error)
            }
          }}>Click</button>


<button onClick={async()=>{
            try {
              let carrierId = await axios.get('/users/specific')
              console.log(carrierId)
            } catch (error) {
              console.log(error)
            }
          }}>Click</button> */}
      
{/* <a href="https://farley-renderv4-backend.onrender.com/auth/google">  <img src=".\signin-assets\google_signin_buttons\web\2x\btn_google_signin_light_focus_web@2x.png"></img>  </a>

<a href="https://farley-renderv4-backend.onrender.com/logout">log out</a> */}

{/* <button onClick={()=>{
  try {
    let result = axios.get('https://farley-renderv4-backend.onrender.com/auth/google')
    console.log(result)
  } catch (error) {
    
  }

}}>TestClick</button> */}
Farley
{/* {name?(<p>{name}</p>):null} */}
      </header>
    </div>
  );
}

export default App;
